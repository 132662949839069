const data = [
    {
        id: '0',
        fullName: 'Антонов Денис',
        goldenQuote: 'Еще по одной?',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin bibendum eros et commodo egestas. Duis quis laoreet felis. Nunc egestas vestibulum nibh molestie varius. Maecenas dignissim accumsan ipsum vitae interdum. Fusce sagittis, erat nec venenatis varius, mi risus ornare mi, vitae pulvinar tellus dui ac eros. Integer vel diam ac massa euismod dapibus. Fusce ultrices nulla orci, sed ultricies purus blandit at.',
        img: './images/denis1.png',
        tg: 'https://t.me/Leg0w1sk0',
        subs: [
            {
                name: 'Друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал'],
                price: 2000,
            },
            {
                name: 'Друг+',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 2 часа в неделю'],
                price: 5000,
            },
            {
                name: 'Лучший друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 4 часа в неделю', 'Возможность поиграть в любую игру на ваш выбор 2 часа в неделю'],
                price: 10000,
            },
        ],
    },
    {
        id: '1',
        fullName: 'Василевский Леонид',
        goldenQuote: 'Я срал. И какал.',
        desc: 'Proin placerat convallis dolor, eget rhoncus tellus euismod in. Integer et velit vitae metus maximus tincidunt eget in tellus. Mauris sollicitudin, sem eget viverra aliquam, neque eros imperdiet leo, quis malesuada ex dui id est. Pellentesque at tellus tempor, egestas lectus in, semper risus. Donec mauris neque, faucibus commodo ultrices ut, fermentum blandit neque. Quisque tincidunt diam fringilla eros feugiat, id pellentesque est iaculis. Aliquam pellentesque suscipit ex sit amet egestas. Nulla ac dolor semper, ultrices ex ut, consectetur odio. Fusce iaculis aliquam velit at efficitur. Praesent tristique magna et eros tempor accumsan. Morbi pulvinar eros sit amet dapibus sagittis. Aenean ac magna nec odio pulvinar blandit.',
        img: './images/leonid1.png',
        tg: 'https://t.me/l4ikadpulukl',
        subs: [
            {
                name: 'Друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал'],
                price: 2000,
            },
            {
                name: 'Друг+',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 2 часа в неделю'],
                price: 5000,
            },
            {
                name: 'Лучший друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 4 часа в неделю', 'Возможность поиграть в любую игру на ваш выбор 2 часа в неделю'],
                price: 10000,
            },
        ],
    },
    {
        id: '2',
        fullName: 'Елисеев Матвей',
        goldenQuote: 'Твое говно не такое важное. Пойми правильно.',
        desc: '213-y5-2-3hrjdshf;lah;dfjsa;fd',
        img: './images/matv1.png',
        tg: 'https://t.me/bnjlka',
        subs: [
            {
                name: 'Друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал'],
                price: 2000,
            },
            {
                name: 'Друг+',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 2 часа в неделю'],
                price: 5000,
            },
            {
                name: 'Лучший друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 4 часа в неделю', 'Возможность поиграть в любую игру на ваш выбор 2 часа в неделю'],
                price: 10000,
            },
        ],
    },
    {
        id: '3',
        fullName: 'Маслов Глеб',
        goldenQuote: 'У тебя нет отца? Называй меня папочка.',
        desc: 'gjpafdpghpdfoshgopasdfgsdgfsd',
        img: './images/gleb1.png',
        tg: 'https://t.me/unknownnumb3r',
        subs: [
            {
                name: 'Друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал'],
                price: 2000,
            },
            {
                name: 'Друг+',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 2 часа в неделю'],
                price: 5000,
            },
            {
                name: 'Лучший друг',
                options: ['Гарантированный ответ в мессенджерах', 'Доступ в закрытый телеграмм канал', 'Возможность поиграть в любую игру на выбор почетного представителя ренессанса 4 часа в неделю', 'Возможность поиграть в любую игру на ваш выбор 2 часа в неделю'],
                price: 10000,
            },
        ],
    },
];

export default data;