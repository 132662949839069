import styles from './ShoppingCart.module.css';

import { useCart } from '../contexts/ShoppingCartContext';

function ShoppingCart() {
    const {cartItems} = useCart();

    return(
        <div className={styles.content}>
            <h1>Корзина</h1>
            {!cartItems.length && <p>Корзина пуста!</p>}
            <ul>
                {cartItems.map((item, index) => {
                    return(
                        <li key={index}>{`${item.name} ${item.type} ${item.price}`}</li>
                    );
                })}
            </ul>
        </div>
    );
}

export default ShoppingCart;