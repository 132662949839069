import React, { useState } from 'react';

const message = 'Просят запуск Stable Duffusion';

function LinkWithCheck({ url, children }) {
    const [isAvailable, setIsAvailable] = useState(true);

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(url, { method: 'HEAD' });
            console.log('Response:', response);
            if (response.ok) {
                window.open(url, '_blank');
            } else {
                setIsAvailable(false);
                fetch("https://rnsns.ru.net/api/sendMessage")
            }
        } catch (error) {
            setIsAvailable(false);
            fetch("https://rnsns.ru.net/api/sendMessage")
        }
    };

    return (
        <>
            <a href={url} onClick={handleClick}>
                {children}
            </a>
            {!isAvailable && <p>Напоминалка отправлена члену Ренессанса. Попробуйте через 10 минут.</p>}
        </>
    );
}

export default LinkWithCheck;