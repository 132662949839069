import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";

function SharedHeader() {
    return (
        <>
            <NavBar />
            <Outlet />
        </>
    );
}

export default SharedHeader;