import React from 'react';
import styles from './ModalWindow.module.css';
import LinkWithCheck from './LinkWithCheck';

const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <p className={styles.modalText}>Stable Duffusion на мощностях члена Ренессанса.</p>
                <LinkWithCheck url="https://188.243.72.107:444">Клик</LinkWithCheck>
            </div>
        </div >
    );
};

export default Modal;