import React, {createContext, useContext, useState} from "react";

const ShoppingCartContext = createContext();

export const useCart = () => useContext(ShoppingCartContext);

export const CartProvider = ({children}) => {
    const [cartItems, setCartItems] = useState([]);

    const addToCart = (product) => {
        setCartItems((prevItems) => [...prevItems, product]);
    }

    const removeFromCart = (productId) => {
        setCartItems(prevItems => prevItems.filter(item => item.id !== productId));
    }

    const clearCart = () => {setCartItems([]);}

    return(
        <ShoppingCartContext.Provider value={{cartItems, addToCart}} >
            {children}
        </ShoppingCartContext.Provider>
    );
}