import subList from '../subList';
import styles from './PriceList.module.css';

import SubCard from '../components/SubCard';
import { useState } from 'react';

function PriceList() {
    const [clickedIndex, setClickedIndex] = useState(null);
    const [textBtn, setTextBtn] = useState(['Купить!', 'В корзине!']);

    function clickedBtnHandler(index) {
        (clickedIndex === index) ? setClickedIndex(null) : setClickedIndex(index);
    }

    return (
        <div className={styles.content}>
            <h1>Прайс-Лист</h1>
            <p>Представляем вашему вниманию уникальные подписки, которые откроют двери в мир сообщества "Ренессанс" — пространство, где собираются умные, талантливые и успешные люди, стремящиеся к великим свершениям. Каждая из наших подписок предоставит вам доступ к эксклюзивным мероприятиям, ценным знаниям и мощной сети контактов.</p>
            <div className={styles.cards}>
                {subList.map((sub, index) => {
                    return(
                        <SubCard key={index} sub={sub} isClicked={clickedIndex === index} setIsClicked={() => clickedBtnHandler(index)} textBtn={textBtn} />
                    );
                })}
            </div>
        </div>
    );
}

export default PriceList;