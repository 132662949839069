import { useParams } from "react-router-dom";
import data from "../data";
import SubCard from "../components/SubCard";
import { useCart } from "../contexts/ShoppingCartContext";

import styles from './Member.module.css';
import { useState } from "react";

function Member() {
    const {membersId} = useParams();
    const member = data.find(member => member.id === membersId);
    const {id, fullName, goldenQuote, desc, img, subs} = member;
    const [clickedIndex, setClickedIndex] = useState(null);
    const [textBtn, setTextBtn] = useState(['Купить!', 'В корзине!']);
    const {addToCart} = useCart();

    function clickedBtnHandler(index) {
        if (clickedIndex === index) {
            setClickedIndex(null);
        } else {
            setClickedIndex(index);
            addToCart({
                id: id,
                name: fullName,
                type: subs[index].name,
                price: subs[index].price,
            });
        }
    }

    return (
        <div className={styles.content}>
            <section className={styles.card}>
                <img src={`.${img}`} alt={`Photo ${fullName}`} />
                <div className={styles.cardInfo}>
                    <h1 className={styles.underline}>{fullName}</h1>
                    <div className={styles.cardInfoMore}>
                        <h3>"{goldenQuote}"</h3>
                        <p>{desc}</p>
                    </div>
                </div>
            </section>
            <section className={styles.subs}>
                {subs.map((sub, index) => {
                    return(
                        <SubCard key={index} sub={sub} isClicked={clickedIndex === index} setIsClicked={() => clickedBtnHandler(index)} textBtn={textBtn}/>
                    );
                })}
            </section>
        </div>
    );
}

export default Member;