import { IconContext } from 'react-icons';
import { FaCheck } from "react-icons/fa";

import styles from './SubCard.module.css';

function SubCard({sub, isClicked, setIsClicked, textBtn}) {
    const {name, options, price} = sub;

    function btnClickHandler() {
        setIsClicked();
    }

    return(
        <article className={styles.card}>
            <h1 className={styles.underline}>{name}</h1>
            <ul>
                {options.map((option, index) => {
                    return(
                        <li key={index}>
                            <i><IconContext.Provider value={{className: `${styles.checkIcon}`}} >
                                <FaCheck />
                            </IconContext.Provider></i> {option}
                        </li>
                    );
                })}
            </ul>
            <div className={styles.buyBtnWrapper}>
                <h3>{price}₽/нед</h3>
                <button className={`${styles.buyBtn} ${isClicked ? styles.clicked : styles.unClicked}`} onClick={btnClickHandler}>{!isClicked ? textBtn[0] : textBtn[1]}</button>
            </div>
        </article>
    );
}

export default SubCard;