import MemberCard from "../components/MemberCard";

import styles from './Members.module.css';

import data from "../data";

function Members() {
    return(
        <div className={styles.content}>
            <h1>Представители Ренессанса</h1>
            <div className={styles.cards}>
                {data.map(member => {
                    return(
                        <MemberCard key={member.id} id={member.id} member={member} />
                    );
                })}
            </div>
        </div>
    )
}

export default Members;