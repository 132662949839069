import styles from './Home.module.css'

import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className={styles.content}>
            <h1>Ренессанс</h1>
            <p>Добро пожаловать в сообщество "Ренессанс" — место, где встречаются веселые, умные и талантливые люди, объединенные стремлением к успеху мирового масштаба. Представьте себе окружение, где каждый день — это источник вдохновения и новые возможности, а каждый человек — это уникальная личность, готовая поделиться своим опытом и знаниями.</p>
            <h3>Почему Ренессанс?</h3>
            <ul>
                <li>Уникальная атмосфера: В нашем сообществе царит атмосфера позитива и творчества. Здесь вы найдете друзей, которые поддержат ваши идеи и помогут воплотить их в жизнь.</li>
                <li>Разнообразие талантов: Среди наших членов — успешные предприниматели, художники, ученые, и новаторы. Вместе мы создаем синергию, которая позволяет каждому раскрыть свой потенциал.</li>
                <li>Путь к успеху: Мы организуем мероприятия, мастер-классы и семинары с участием мировых лидеров и экспертов. Это уникальная возможность учиться у лучших и применять полученные знания на практике.</li>
                <li>Социальные и культурные мероприятия: Наши встречи — это не только деловые мероприятия, но и яркие события, наполненные весельем и культурным обменом. Концерты, выставки, вечеринки и интеллектуальные игры — каждый найдет что-то по душе.</li>
                <li>Взаимопомощь и поддержка: Мы верим в силу сообщества и поддерживаем друг друга на пути к успеху. В "Ренессансе" вы всегда найдете помощь и совет от людей, которые понимают ваши цели и амбиции.</li>
            </ul>
            <h3>Присоединяйтесь к нам!</h3>
            <p>Участие в "Ренессансе" — это инвестиция в ваше будущее. Здесь вы найдете единомышленников, друзей и партнеров, которые помогут вам достичь новых высот. Время, проведенное с членами нашего сообщества, обогатит вас новыми идеями, откроет новые горизонты и станет важным шагом на пути к вашему успеху.</p>
            <p>Не упустите шанс стать частью "Ренессанса" — сообщества, где мечты становятся реальностью. Присоединяйтесь к нам, и давайте вместе создадим будущее, о котором мы мечтаем!</p>
            <div className={styles.wrapper}>
                <Link to='members' className={styles.enjoyBtn}>Присоединяйся!</Link>
            </div>
        </div>
    );
}

export default Home;