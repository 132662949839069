const subList = [
    {
        id: '100',
        name: 'Друг Ренессанса',
        options: ['Доступ к телеграмм чату Ренессанса', 'Доступ к дискорд каналу Ренессанса', 'Подписка "Друг" с каждым представителем Ренессанса',],
        price: 20000,
    },
    {
        id: '101',
        name: 'Уважаемый Друг Ренессанса',
        options: ['Доступ к телеграмм чату Ренессанса', 'Доступ к дискорд каналу Ренессанса', 'Подписка "Друг+" с каждым представителем Ренессанса',],
        price: 50000,
    },
    {
        id: '102',
        name: 'Кандидат в Ренессанс',
        options: ['Доступ к телеграмм чату Ренессанса', 'Доступ к дискорд каналу Ренессанса', 'Подписка "Лучший Друг" с каждым представителем Ренессанса', 'Возможность побывать на сходке Ренессанса'],
        price: 100000,
    },
];

export default subList;