import styles from './MemberCard.module.css';

import { Link } from 'react-router-dom';
import { FaTelegram } from "react-icons/fa";
import { IconContext } from 'react-icons';

function MemberCard({id, member}) {
    const {fullName, goldenQuote, img, tg} = member;
    return (
        <article className={styles.card}>
            <img src={img} alt={`Photo ${fullName}`} />
            <div className={styles.cardInfo}>
                <h2 className={styles.underline}>{fullName}</h2>
                <h3>"{goldenQuote}"</h3>
                <div className={styles.buttons} >
                    <a href={tg} target='blank'>
                        <IconContext.Provider value={{className: `${styles.tgBtn}`}} >
                            <FaTelegram />
                        </IconContext.Provider>
                    </a>
                    <Link to={`/members/${id}`} className={styles.moreBtn}>Дружить!</Link>
                </div>
            </div>
        </article>
    );
}

export default MemberCard;